import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  fill: "#662E9C",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M15.9998 2.66699C14.2489 2.66699 12.5151 3.01187 10.8974 3.68193C9.27972 4.35199 7.80986 5.33412 6.57175 6.57224C4.07126 9.07272 2.6665 12.4641 2.6665 16.0003C2.6665 21.8937 6.49317 26.8937 11.7865 28.667C12.4532 28.7737 12.6665 28.3603 12.6665 28.0003V25.747C8.97317 26.547 8.1865 23.9603 8.1865 23.9603C7.57317 22.4137 6.7065 22.0003 6.7065 22.0003C5.49317 21.1737 6.79984 21.2003 6.79984 21.2003C8.13317 21.2937 8.83984 22.5737 8.83984 22.5737C9.99984 24.6003 11.9598 24.0003 12.7198 23.6803C12.8398 22.8137 13.1865 22.227 13.5598 21.8937C10.5998 21.5603 7.49317 20.4137 7.49317 15.3337C7.49317 13.8537 7.99984 12.667 8.8665 11.7203C8.73317 11.387 8.2665 10.0003 8.99984 8.20033C8.99984 8.20033 10.1198 7.84033 12.6665 9.56033C13.7198 9.26699 14.8665 9.12033 15.9998 9.12033C17.1332 9.12033 18.2798 9.26699 19.3332 9.56033C21.8798 7.84033 22.9998 8.20033 22.9998 8.20033C23.7332 10.0003 23.2665 11.387 23.1332 11.7203C23.9998 12.667 24.5065 13.8537 24.5065 15.3337C24.5065 20.427 21.3865 21.547 18.4132 21.8803C18.8932 22.2937 19.3332 23.107 19.3332 24.347V28.0003C19.3332 28.3603 19.5465 28.787 20.2265 28.667C25.5198 26.8803 29.3332 21.8937 29.3332 16.0003C29.3332 14.2494 28.9883 12.5156 28.3182 10.8979C27.6482 9.2802 26.666 7.81035 25.4279 6.57224C24.1898 5.33412 22.72 4.35199 21.1023 3.68193C19.4846 3.01187 17.7508 2.66699 15.9998 2.66699Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }